import "date-fns";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";

import { doUpdateMeeting } from "../../services/actions";

const actions = {
  doUpdateMeeting,
};

class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    debugger;
    this.state = { ...this.props.meeting1, updating: false };
  }

  componentDidMount() {
    const inputText = document.getElementById("autocomplete");
    const options = {
      types: ["geocode"],
    };
    this.autocomplete = new window.google.maps.places.Autocomplete(
      inputText,
      options
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.meeting1.location !== this.state.location &&
      !this.state.updating
    ) {
      this.setState({ ...nextProps.meeting1 });
    }
  }

  handlePlaceChanged = () => {
    const { doUpdateMeeting } = this.props;
    const place = this.autocomplete.getPlace();
    const location = place.formatted_address;
    const { id, title, startDate, startTime, endTime } = this.state;

    doUpdateMeeting({ id, title, location, startDate, startTime, endTime });
    this.props.setShowInput(false);
    this.setState({ location });
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <TextField
          style={{ width: "100%" }}
          id="autocomplete"
          variant="outlined"
          label="Location"
          fullWidth
          autoFocus
          data-testid="location-data-input"
          onBlur={() => this.props.setShowInput(false)}
          value={this.state.location}
          onChange={(e) =>
            this.setState({ location: e.target.value, updating: true })
          }
        />
      </div>
    );
  }
}

export default compose(connect(null, actions), withRouter)(LocationForm);
