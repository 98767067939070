/**
 * Footer - contents below the App
 */

import React from "react";
import { withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    marginTop: 46,
  },
  sentence: {
    marginTop: 16,
  },
}))((props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <iframe
        title="feedbackform"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfOZQGw3dwWgrZFtLaq-tGqY1uz9iKav15kjWMeGuLS1II6Tg/viewform?embedded=true"
        width="100%"
        height="850"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
      <hr />
      <div className={classes.sentence}>
        Agendarr!! &copy; 2020 Christian Ubbesen
      </div>
    </div>
  );
});
