export default {
  apiKey: "AIzaSyBIGgIm1K3C93BMNfZMKSRqvso5CP2wXjQ",
  authDomain: "agendarr-dc3f6.firebaseapp.com",
  databaseURL: "https://agendarr-dc3f6.firebaseio.com",
  projectId: "agendarr-dc3f6",
  storageBucket: "agendarr-dc3f6.appspot.com",
  messagingSenderId: "337025587004",
  appId: "1:337025587004:web:31d7807ddb56338ff2b039",
  measurementId: "G-423JTMPVH9",
};
