import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import Sortable from "sortablejs";

import { withStyles, TextField } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import UpdateIcon from "@material-ui/icons/Update";

import AddIcon from "@material-ui/icons/Add";

import Activity from "./activity";

import {
  doSortActivities,
  doUpdateActivity,
  doCreateActivity,
  doUpdateMeeting,
} from "../../services/actions";
import {
  formatDuration,
  parseDuration,
} from "../../app/common/util/durationUtil";

//Dialog part
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Input from "@material-ui/core/Input";
import MenuItem from '@material-ui/core/MenuItem';
import MaskedInput from "react-text-mask";
import keyCodes from "../../app/common/util/keyCodes";
import activity from "./activity";
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import LabelContainer from "../Labels"

const styles = (theme) => ({
  root: {
    "& .sortable-ghost": {
      opacity: 0,
    },
    "& .sortable-drag": {
      opacity: 1,
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  toolbar: {},
  duration: {
    paddingRight: 104,
    fontWeight: "bold",
  },
  iconStyle: {
    top: "6px",
    position: "relative",
    cursor: "pointer",
    color: "red",
  },
  textInRed: {
    color: "red",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    minHeight: 56,
    margin: 10,
    flexWrap: "nowrap",
  },
  contentText: {
    padding: "6px 0 7px",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    textAlign: "center"
  },
  typeText:{
    marginRight: 30,
    padding: "6px 0 7px",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
  },
  inputContent: {
    marginRight: 30,
  },
  selectType:{
    marginRight: 30,
    minWidth: 80
  },
  inputDuration: {
    marginRight: 30,
    width: 56,
    "& input": {
      textAlign: "end",
    },
  },
  colorBtn:{
    width: 70,
    height: 10,
    margin: 2
  }
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Agenda = (props) => {
  const {
    activities,
    meetingId,
    classes,
    doSortActivities,
    doCreateActivity,
    doUpdateActivity,
    meeting,
  } = props;
  let time = 0;
  let { start_time } = props;
  const listEl = useRef(null);
  const activitiesSorted = activities.sort((a1, a2) => a1.index - a2.index);
  const durationCount = activities
    .filter((a) => {
      return !a.isNewActivity;
    })
    .reduce((sum, a) => sum + a.duration, 0);
  const endTime = meeting && meeting.endTime ? meeting.endTime.seconds : null;
  const startTime = meeting && meeting.startTime.seconds;
  const diffBetweenEndAndStartTime = endTime
    ? Math.round((endTime - startTime) / 60 / 1000)
    : 1000000000000000; // just to be sure when the endTime si null we will not have the error message
  console.log(meeting);
  debugger;

  const isDiffBetweenTotalAndEndTimeOk =
    diffBetweenEndAndStartTime - durationCount >= 0 ? true : false;

  const[activity, setActivity] = useState({});
  const types = ["Information", "Discussion", "Decision", "Other"];

  const [contentInputValue, setContentInputValue] = useState("");
  const [typeSelectValue, setTypeValue] = useState("");
  const [durationInputValue, setDurationInputValue] = useState(
    formatDuration(0)
  );
  const [descriptionInputValue, setDescriptionInputValue] = useState("");
  const [titleValue, setTitleValue] = useState("");

  const onKeyDown = (e) => {

  };
  const DurationInput = (props) => {
    const { inputRef, ...other } = props;
    // implement `InputElement` interface
    // React.useImperativeHandle(inputRef, () => ({
    //   focus: () => inputRef.focus(),
    //   value: inputRef.value,
    // }));

    return (
      <MaskedInput
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
        {...other}
      />
    );
  };

  const contentInput = useRef(null);
  let typeSelect = useRef();
  let durationInput = useRef();

  useEffect(() => {
    if (listEl.current) {
      new Sortable(listEl.current, {
        handle: "li",
        draggable: "li",
        onUpdate: doSortActivities,
        animation: 150,
        easing: "cubic-bezier(1, 0, 0, 1)",
        supportPointer: false,
      });
    }
  }, [doSortActivities]);

  const updateEndTime = () => {
    if (!isDiffBetweenTotalAndEndTimeOk) {
      const { doUpdateMeeting } = props;
      const { id, title, startDate, endTime, startTime, location } = meeting;
      const newEndDate =
        endTime.seconds -
        (diffBetweenEndAndStartTime - durationCount) * 60 * 1000;
      doUpdateMeeting({
        id,
        title,
        startTime,
        location,
        endTime: { seconds: newEndDate },
        startDate,
      });
    }
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLabel = Boolean(anchorEl);
  const labelId = openLabel ? 'label-popover' : undefined;

  const handleClickOpen = (activity) => {

    setActivity(activity);
    setTitleValue(activity.content);
    setContentInputValue(activity.content);
    setDurationInputValue(formatDuration(activity.duration));
    setDescriptionInputValue(activity.description);
    setTypeValue(activity.type);
    setDialogOpen(true);
  };
  const handleClickSave = () => {
    activity.duration = parseDuration(durationInputValue);
    activity.content = contentInputValue;
    activity.type = typeSelectValue;
    activity.description = descriptionInputValue;
    doUpdateActivity(activity);
    setDialogOpen(false);
  };
  const handleClose = () => {
    setDialogOpen(false);
  }

  const handleLabelClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLabelClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper data-testid="agenda" elevation={0} className={classes.root}>
      <List
        ref={listEl}
        data-testid="agenda-activity-list"
        className="draggable-container"
      >
        {activitiesSorted.map((activity, index) => {
          if (start_time && index > 0) {
            start_time += time;
          }
          time = activity.duration;
          return (

          <Activity
              key={activity.id + "" + activity.isNewActivity}
              activity={activity}
              start_time={start_time}
              index={activity.index}
              meetingId={meetingId}
              length={activitiesSorted.length}
              openModal = {() => handleClickOpen(activity)}
            />

          );
        })}
      </List>
      <Dialog  aria-labelledby="customized-dialog-title" onClose={handleClose} open={dialogOpen} data-testid={"dlg-popup"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          {titleValue}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
          {meeting.labels.map((item, index)=>{
            if(activity.labels && activity.labels.indexOf(item.id) >= 0){
                return(
                <Chip
                  className={classes.colorBtn}
                  style={{backgroundColor: `${item.color}`}}
                  key={index}
                  data-testid={`label-item-${index}`}
                />
                )
                }
              })
            }
          </Grid>
          <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.content}
            >
               <TextField
                    inputRef={typeSelect}
                    select
                    id="type"
                    className={classes.selectType}
                    value={typeSelectValue}
                    onChange={(e)=>setTypeValue(e.target.value)}
                    inputProps={{ "data-testid": "activity-type-input" }}
                    SelectProps={{
                        MenuProps: { disablePortal: true }
                    }}
                    //onBlur={applyInputChanges}
              >
                    {types.map((item, index)=><MenuItem key = {index} value={item} data-testid = {"type-dropdown-item"} >{item}</MenuItem>)}
              </TextField>
              <Input
                inputRef={contentInput}
                className={classes.inputContent}
                inputProps={{
                  "aria-label": "Name",
                  "data-testid": "activity-title-input",
                }}
                id="content"
                type="text"
                size="small"
                value={contentInputValue}
                onChange={(e) => {
                  setContentInputValue(e.target.value);
                  setTitleValue(e.target.value);
                }}
                //onBlur={applyInputChanges}
              />
              <Input
                inputRef={durationInput}
                className={classes.inputDuration}
                type="text"
                value={durationInputValue}
                onBlur={(e)=>setDurationInputValue(e.target.value)}
                inputProps={{ "data-testid": "activity-duration-input" }}
                inputComponent={DurationInput}

              />

          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.content}
          >

          <TextField
            label="Description"
            multiline
            fullWidth
            rows={4}
            value={descriptionInputValue}
            inputProps={{ "data-testid": "activity-description-input" }}
            onChange = {(e)=>setDescriptionInputValue(e.target.value)}
            variant="outlined"
            style={{marginRight:"20px"}}
          />

          </Grid>
          <Grid container justify="flex-end">
            <Button aria-describedby={activity.id} variant="contained" color="primary" onClick={handleLabelClick} data-testid={"test-btnOpenLabel"} >
                Labels
              </Button>
              <Popover
                  id={activity.id}
                  open={openLabel}
                  anchorEl={anchorEl}
                  onClose={handleLabelClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  data-testid="test-label-popover"
                >
                  <LabelContainer labels={meeting.labels} activity = {activity} closeLabel = {setAnchorEl} meetingId={meetingId}/>
                </Popover>
            </Grid>
            <Typography className={classes.contentText}>Created at: {new Date(activity.created?.seconds * 1000).toLocaleDateString() + " " + new Date(activity.created?.seconds * 1000).toLocaleTimeString()}</Typography>

            <Typography className={classes.contentText}>Last updated at: {new Date(activity.updated?.seconds * 1000).toLocaleDateString() + " " + new Date(activity.updated?.seconds * 1000).toLocaleTimeString()}</Typography>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickSave} color="primary" data-testid={"dlg-btnSave"}>
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        wrap="wrap-reverse"
      >
        <div>
          <Button
            data-testid="agenda-add-activity-button"
            onClick={doCreateActivity}
            color="primary"
            disableElevation
            aria-label="add"
          >
            Add new activity
          </Button>
        </div>
        <Typography
          data-testid="agenda-duration"
          className={`${classes.duration} ${
            !isDiffBetweenTotalAndEndTimeOk ? classes.textInRed : ""
          }`}
        >
          {formatDuration(durationCount)} total{" "}
          {!isDiffBetweenTotalAndEndTimeOk && (
            <UpdateIcon
              data-testid="update-icon"
              className={classes.iconStyle}
              onClick={() => updateEndTime()}
            />
          )}
        </Typography>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const actions = {
  doCreateActivity,
  doSortActivities,
  doUpdateActivity,
  doUpdateMeeting,
};

export default connect(mapStateToProps, actions)(withStyles(styles)(Agenda));
