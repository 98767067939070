import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#376eff",
    },
    text: {
      primary: "#000",
    },
    background: {
      default: "#f6f7fa",
    },
    border: {
      light: "#f6f7fa",
      dark: "rgba(0, 0, 0, 0.2)",
    },
  },
  typography: {
    h2: {
      fontSize: 36,
      lineHeight: 1.5,
      fontWeight: "bold",
      "@media (max-width:600.95px)": {
        fontSize: 30,
        lineHeight: 1,
      },
    },
    h3: {
      fontSize: 26,
      lineHeight: 1.5,
      fontWeight: "bold",
      letterSpacing: "0.00938em",
      "@media (max-width:600.95px)": {
        fontSize: 24,
        lineHeight: 1,
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "bold",
      },
    },
  },
});
