import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  formatDuration,
  parseDuration,
} from "../../app/common/util/durationUtil";
import keyCodes from "../../app/common/util/keyCodes";

import { withStyles, TextField } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import ReorderIcon from "@material-ui/icons/Reorder";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import MaskedInput from "react-text-mask";
import MenuItem from '@material-ui/core/MenuItem';
import { doUpdateActivity, doDeleteActivity } from "../../services/actions";

const styles = (theme) => ({
  root: {
    marginBottom: 20,
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: 14,
    padding: "10px 0 10px 20px",
    background: "#fff",
  },
  number: {
    whiteSpace: "nowrap",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    marginRight: 5,
    color: "rgba(0, 0, 0, 0.54)",
  },
  content: {
    minHeight: 56,
    flexWrap: "nowrap",
  },
  contentText: {
    marginRight: 30,
    padding: "6px 0 7px",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
  },
  typeText:{
    marginRight: 30,
    padding: "6px 0 7px",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
  },
  inputContent: {
    marginRight: 30,
  },
  selectType:{
    marginRight: 30,
    minWidth: 80
  },
  inputDuration: {
    marginRight: 30,
    width: 56,
    "& input": {
      textAlign: "end",
    },
  },
});

const DurationInput = (props) => {
  const { inputRef, ...other } = props;
  // implement `InputElement` interface
  // React.useImperativeHandle(inputRef, () => ({
  //   focus: () => inputRef.focus(),
  //   value: inputRef.value,
  // }));

  return (
    <MaskedInput
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
      {...other}
    />
  );
};

const Activity = (props) => {
  const {
    activity: { id, content = "", type = "",  duration = 0, isNewActivity } = {},
    openModal,
    start_time = 0,
    index,
    classes,
    doDeleteActivity,
    doUpdateActivity,
    length,
  } = props;
  const types = ["Information", "Discussion", "Decision", "Other"];
  const [editMode, setEditMode] = useState(false);
  const [contentInputValue, setContentInputValue] = useState(content);
  const [typeSelectValue, setTypeValue] = useState(type);
  const [durationInputValue, setDurationInputValue] = useState(
    formatDuration(duration)
  );
  const contentInput = useRef(null);
  let typeSelect = useRef();
  let durationInput = useRef();
  const applyInputChanges = () => {
    console.log("applyInputChanges");
    doUpdateActivity({
      id,
      content: contentInputValue,
      type: typeSelectValue,
      duration: parseDuration(durationInputValue),
      index,
      isNewActivity: false,
    });
  };

  const goToEditMode = () => {
    setEditMode(true);
    setContentInputValue(props.activity.content);
    setTypeValue(props.activity.type);
    setTimeout(() => {
      contentInput && contentInput.current && contentInput.current.focus();
    }, 100);
  };

  const exitEditMode = () => {
    console.log("exit") ;
    applyInputChanges();
    setEditMode(false);
  };
  const onKeyDown = (e) => {
    if(e.keyCode == keyCodes.ENTER ){
      if( e.currentTarget.id == "content"){
        durationInput.current.focus();
        return;
      }
      else if(e.currentTarget.id == "duration"){
        typeSelect.current.focus();
        return;
      }
    }
    if (e.keyCode === keyCodes.ESC || e.keyCode === keyCodes.ENTER) {
      exitEditMode();
    }
  };

  const handleOpenModal = ()=>{
    openModal(props.activity);
  }
  useEffect(() => {
    if (props.activity.isNewActivity) {
      goToEditMode();
    }
  }, []);

  return (
    <ListItem data-testid="activity" id={id} className={classes.root}>
      <ListItemIcon>
        <ReorderIcon />
      </ListItemIcon>
      <Typography
        data-testid="activity-order-indicator"
        className={classes.number}
      >
        §{index + 1}.
      </Typography>

      {!editMode && (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          data-testid={`activity-popup-${index}`}
          className={classes.content}
          onClick ={handleOpenModal}
        >
          { Number(start_time) > 0 &&
            (<Typography
              data-testid="activity-start-time"
              className={classes.contentText}
            >
              {formatDuration(start_time)}
            </Typography>)
          }
          <Typography
            data-testid="activity-title"
            className={classes.contentText}
            onClick={goToEditMode}
          >
            {content}
          </Typography>
          <Typography
            data-testid="activity-duration"
            className={classes.contentText}
          >
            {formatDuration(duration)}
          </Typography>
          <Typography
            data-testid="activity-type"
            className={classes.typeText}
          >
            {type}
          </Typography>
        </Grid>
      )}
      {editMode && (
        <ClickAwayListener onClickAway={exitEditMode}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.content}
          >
            { Number(start_time) > 0 &&
            (<Typography
              data-testid="activity-start-time"
              className={classes.contentText}
            >
              {formatDuration(start_time)}
            </Typography>)
          }
            <Input
              inputRef={contentInput}
              className={classes.inputContent}
              inputProps={{
                "aria-label": "Name",
                "data-testid": "activity-title-input",
              }}
              id="content"
              type="text"
              size="small"
              value={contentInputValue}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                setContentInputValue(e.target.value);
              }}
              //onBlur={applyInputChanges}
            />
            <Input
              inputRef={durationInput}
              className={classes.inputDuration}
              type="text"
              id = "duration"
              value={durationInputValue}
              onKeyDown={onKeyDown}
              onChange={(e) => setDurationInputValue(e.target.value)}
              //onBlur={applyInputChanges}
              inputProps={{ "data-testid": "activity-duration-input" }}
              inputComponent={DurationInput}

            />
            <TextField
                  inputRef={typeSelect}
                  select
                  id = "type"
                  className={classes.selectType}
                  value={typeSelectValue}
                  onKeyDown={onKeyDown}
                  onChange={(e)=>setTypeValue(e.target.value)}
                  inputProps={{ "data-testid": "activity-type-input" }}
                  SelectProps={{
                      MenuProps: { disablePortal: true }
                  }}
                  //onBlur={applyInputChanges}
            >
                  {types.map((item, index)=><MenuItem key = {index} value={item} data-testid = {"type-dropdown-item"} >{item}</MenuItem>)}
          </TextField>
          </Grid>
        </ClickAwayListener>
      )}
      {editMode && (
        <>
          <ListItemIcon />
          <ListItemIcon />
        </>
      )}
      {!editMode && (
        <>
          <ListItemIcon>
            <IconButton
              data-testid="activity-edit-button"
              onClick={goToEditMode}
            >
              <EditIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemIcon>
            <IconButton
              data-testid="activity-delete-button"
              onClick={() => doDeleteActivity(id)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemIcon>
        </>
      )}
    </ListItem>
  );
};

export default connect(null, { doDeleteActivity, doUpdateActivity })(
  withStyles(styles)(Activity)
);
