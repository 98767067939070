import "date-fns";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { doUpdateMeeting } from "../../services/actions";

const actions = {
  doUpdateMeeting,
};

const AgendaDate = (props) => {
  // The first commit of Material-UI
  const { meeting, activities } = props;

  const [selectedDate, setSelectedDate] = React.useState(
    new Date(meeting.startDate.seconds)
  );
  const [selectedStartTime, setSelectedStartTime] = React.useState(
    new Date(meeting.startTime.seconds)
  );
  const [selectedEndTime, setSelectedEndTime] = React.useState(meeting.endTime?meeting.endTime.seconds:null);
  const [editMode, setEditMode] = useState(false);
  const inputEl = useRef(null);

  const ss = meeting.endTime? (selectedEndTime != meeting.endTime.seconds?setSelectedEndTime(moment(meeting.endTime.seconds)):""):""


  const handleDateChange = (date) => {
    const { doUpdateMeeting } = props;
    setSelectedDate(date);
    const { id, title, startTime, endTime, location } = meeting;
    doUpdateMeeting({
      id,
      title,
      startTime,
      location,
      endTime,
      startDate: { seconds: date.toDate().getTime() },
    });
    exitEditMode();
  };

  const handleStartTimeChange = (date) => {
    const { doUpdateMeeting } = props;
    setSelectedStartTime(date);
    const { id, title, startDate, endTime, location } = meeting;
    doUpdateMeeting({
      id,
      title,
      startTime: { seconds: date.toDate().getTime() },
      location,
      endTime,
      startDate,
    });
    exitEditMode();
  };

  const handleEndTimeChange = (date) => {
    const { doUpdateMeeting } = props;
    setSelectedEndTime(date);
    const { id, title, startTime, startDate, location } = meeting;
    doUpdateMeeting({
      id,
      title,
      startTime,
      location,
      endTime: { seconds: date.toDate().getTime() },
      startDate,
    });
    exitEditMode();
  };

  const exitEditMode = () => {
    setEditMode(false);
  };

  const goToEditMode = () => {
    setEditMode(true);
  };


  return (
    <Grid item xs={12} sm={12} md={8} onMouseLeave={() => setEditMode(false)}>
      {!editMode && (
        <div onMouseOver={goToEditMode}  data-testid="agenda-date-data">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              style={{ width: "100%" }}
              disableToolbar
              inputVariant="outlined"
              margin="normal"
              format="DD MMM YYYY"
              id="date-picker-inline"
              label="Date"
              value={new Date(meeting.startDate.seconds)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <Grid item xs={6} sm={6} md={12}>
              <KeyboardTimePicker
                margin="normal"
                style={{ width: "50%" }}
                id="time-picker"
                label="Start Time"
                inputVariant="outlined"
                value={new Date(meeting.startTime.seconds)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                style={{ width: "50%" }}
                id="time-picker"
                label="End Time"
                inputVariant="outlined"
                value={meeting.endTime?new Date(meeting.endTime.seconds):null}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      )}
      {editMode && (
        <ClickAwayListener onClickAway={exitEditMode} data-testid="agenda-date-data-edit">
          <div>
            <div ref={inputEl} />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={{ width: "100%" }}
                disableToolbar
                data-testid="agenda-date-data-edit-start-date"
                inputVariant="outlined"
                margin="normal"
                id="date-picker-inline"
                label="Date"
                format="DD MMM YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <Grid item xs={6} sm={6} md={12}>
                <KeyboardTimePicker
                  margin="normal"
                  style={{ width: "50%"}}
                  id="time-picker"
                  label="Start Time"
                  inputVariant="outlined"
                  value={selectedStartTime}
                  onChange={handleStartTimeChange}
                  error={selectedEndTime && selectedEndTime < selectedStartTime}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  style={{ width: "50%"}}
                  id="time-picker"
                  label="End Time"
                  inputVariant="outlined"
                  value={selectedEndTime}
                  onChange={handleEndTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  error={selectedEndTime && selectedEndTime < selectedStartTime}
                />
                {(selectedEndTime && selectedEndTime < selectedStartTime) && (
                  <span style={{ color: "red" }}>
                    End time must be after the start time of meeting
                  </span>
                )}
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </ClickAwayListener>
      )}
    </Grid>
  );
}

export default compose(connect(null, actions), withRouter)(AgendaDate);
