export default Object.freeze({
  ENTER: 13,
  TAB: 9,
  DELETE: 46,
  ESC: 27,
  SPACE: 32,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  END: 35,
  HOME: 36,
  DEL: 46,
  BACKSPACE: 8,
  INSERT: 45,
  PAGEUP: 33,
  PAGEDOWN: 34,
});
