import React, { useEffect } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Meeting from "../../components/Meeting";
import { doCreateMeeting, getMeetingById } from "../../services/actions";

import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    margin: "auto",
    marginTop: 16,
    minHeight: 400,
  },
  loader: {},
});

const mapState = (state) => {
  let meetingData = {};
  let activitiesData = [];
  
  if (state.firestore.ordered.meetings && state.firestore.ordered.meetings[0]) {
    meetingData = state.firestore.ordered.meetings[0];
  }

  if (
    state.firestore.ordered.agendaActivities &&
    state.firestore.ordered.agendaActivities[0]
  ) {
    activitiesData = state.firestore.ordered.agendaActivities;
  }

  return {
    id: state.meetingData.meeting.id,
    requesting: state.firestore.status.requesting,
    meetingData: meetingData,
    activitiesData: activitiesData || [],
  };
};

const actions = {
  doCreateMeeting,
  getMeetingById,
};

const App = (props) => {
  const {
    meetingData,
    requesting,
    activitiesData,
    classes,
    getMeetingById,
    doCreateMeeting,
  } = props;

  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    const meetingId = path.startsWith("/m/") ? path.slice(3) : undefined;
    if (meetingId) {
      getMeetingById(meetingId);
    } else {
      doCreateMeeting();
    }
  }, [doCreateMeeting, getMeetingById]);

  const loading = Object.values(requesting).some((a) => a === true); // loading while Listening firestore.

  if (loading)
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <CircularProgress className={classes.loader} size={100} />
      </Grid>
    );

  return (
    <div className={classes.root}>
      {meetingData && activitiesData && (
        <Meeting meetingData={meetingData} activities={activitiesData} />
      )}
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((props) => {
    if (!props.id) return [];

    return [
      {
        collection: "meetings",
        doc: props.id,
        storeAs: "meetings",
      },
      {
        collection: "meetings",
        doc: props.id,
        subcollections: [{ collection: "agendaActivities" }],
        storeAs: "agendaActivities",
      },
    ];
  })
)(withStyles(styles)(App));
