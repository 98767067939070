import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";

import AddToCalendar from "react-add-to-calendar";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import CopyIcon from "@material-ui/icons/FileCopy";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import "./style.css"

const AgendaSharing = (props) => {
  // The first commit of Material-UI
  const { meeting, durationCount } = props;
  const { location, endTime, title,startTime } = meeting;
  const endTimeSeconds = endTime?endTime.seconds : startTime.seconds + (durationCount?durationCount*60*1000:30*60*1000)
  const icon = { 'calendar-plus-o': 'left' };
  console.log(endTimeSeconds);
  console.log(durationCount);
  debugger

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div style={{display:"flex"}}>
    <Grid
      container
      alignItems="center"
      justify="flex-end"
      style={{maxHeigth:"40px"}}
      className="other-links"
    >
      <IconButton data-testid="meeting-cp-clipboard-button" onClick={copyLink}>
        <CopyIcon fontSize="small" />
      </IconButton>
      <IconButton
        data-testid="meeting-mailto-button"
        target="_blank"
        href={`mailto:?subject=Collaborate on our meeting agenda&body=Let's work on our meeting agenda together at ${window.location.href}`}
      >
        <EmailIcon fontSize="small" />
      </IconButton>

    </Grid>
    <AddToCalendar
      event={{
        title,
        description: `Welcome to collaborate on our meeting agenda here: ${window.location.href}`,
        location,
        startTime: new Date(startTime.seconds).toISOString(),
        endTime: endTime?new Date(endTime.seconds).toISOString():new Date(endTimeSeconds).toISOString(),
      }}
      data-testid="agenda-event"
      buttonLabel=""
      buttonTemplate={icon}
    />
    </div>
  );
};

export default AgendaSharing;
