import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Sortable from "sortablejs";

import { withStyles, TextField, IconButton } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import cuid from "cuid";
import {
  doCreateLabel,
  doUpdateLabel,
  doDeleteLabel,
  doUpdateActivity,
} from "../../services/actions";
const styles = (theme) => ({
  root: {
    maxWidth: 400,
    minWidth: 400,
    margin: "0 auto",
    padding: 10,
  },
  gridContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  actions: {
    marginTop: 20,
    marginBottom: 20,
  },
  colorBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  colorBtn: {
    width: 60,
    height: 30,
    borderRadius: 4,
  },
  colorItem: {
    width: "100%",
    height: 40,
    color: "white",
    fontSize: 17,
    justifyContent: "space-between",
    borderRadius: 4,
  },
});
const LabelContainer = (props) => {
  const {
    labels = [],
    classes,
    doCreateLabel,
    doUpdateLabel,
    doDeleteLabel,
    meetingId,
    doUpdateActivity,
    closeLabel,
    activity = {},
  } = props;
  const [editMode, setEditMode] = useState(0);
  const [labelName, setLabelName] = useState("");
  const [selectedColor, setSelectedColor] = useState("#61bd4f");
  const [selectedLabel, setSelectedLabel] = useState(-1);
  const [create, setCreate] = useState(false);
  const colors = [
    "#61bd4f",
    "#f2d600",
    "#ff9f1a",
    "#eb5a46",
    "#c377e0",
    "#0079bf",
    "#00c2e0",
    "#51e898",
    "#ff78cb",
    "#344563",
  ];
  const handleClickColor = (color) => {
    console.log(color);
    setSelectedColor(color);
  };
  const createLabel = () => {
    setSelectedLabel(-1);
    setLabelName("");
    setSelectedColor(colors[0]);
    setEditMode(1);
  };
  const backToList = () => {
    if (editMode == 0) {
      closeLabel(null);
      return;
    }
    setSelectedLabel(-1);
    setLabelName("");
    setSelectedColor("");
    setEditMode(0);
  };
  const editLabel = (index) => {
    setSelectedLabel(index);
    setLabelName(labels[index].name);
    setSelectedColor(labels[index].color);
    setEditMode(2);
  };
  const saveLabel = () => {
    if (editMode == 1) {
      doCreateLabel(
        { id: cuid(), name: labelName, color: selectedColor },
        meetingId
      );
    } else if (editMode == 2) {
      doUpdateLabel(
        { id: labels[selectedLabel].id, name: labelName, color: selectedColor },
        meetingId,
        selectedLabel
      );
    }
    backToList();
  };
  const deleteLabel = () => {
    doDeleteLabel(meetingId, selectedLabel);
    backToList();
  };
  const handleClickLabelItem = (labelId) => {
    console.log(labelId);
    if (activity.labels.indexOf(labelId) >= 0) {
      //pop
      activity.labels = activity.labels.filter((label) => {
        return label != labelId;
      });
    } else {
      //add
      activity.labels.push(labelId);
    }
    doUpdateActivity(activity);
  };
  useEffect(() => {}, []);
  console.log(labels);
  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <IconButton
          aria-label="back"
          onClick={backToList}
          style={
            editMode != 0 ? { visibility: "visible" } : { visibility: "hidden" }
          }
        >
          <BackIcon />
        </IconButton>
        <Typography>Labels</Typography>
        <IconButton
          aria-label="close"
          onClick={backToList}
          data-testid="label-popover-closeBtn"
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Divider />
      {editMode == 0 && (
        <List>
          {labels.map((label, index) => {
            return (
              <ListItem
                key={index}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <Chip
                  clickable
                  onClick={() => handleClickLabelItem(label.id)}
                  onDelete={() => {}}
                  deleteIcon={
                    activity.labels.indexOf(label.id) >= 0 ? (
                      <DoneIcon
                        style={{ color: "white" }}
                        data-testid={"done-icon"}
                      />
                    ) : (
                      <DoneIcon
                        style={{ display: "none" }}
                        data-testid={"done-icon"}
                      />
                    )
                  }
                  className={classes.colorItem}
                  style={{ backgroundColor: `${label.color}` }}
                  label={label.name}
                  data-testid={`label-${index}`}
                />
                <IconButton
                  onClick={() => editLabel(index)}
                  data-testid={`label-item-edit-${index}`}
                >
                  <EditIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {editMode != 0 && (
        <Grid>
          <Grid container className={classes.gridContainer}>
            <TextField
              id="filled-full-width"
              label="Name"
              style={{ margin: 8 }}
              placeholder="Enter name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ "data-testid": "label-name-input" }}
              value={labelName}
              onChange={(e) => setLabelName(e.target.value)}
            />
          </Grid>
          <Typography>Select a color</Typography>
          <Grid container className={classes.colorBtnContainer}>
            {colors.map((item, index) => {
              return (
                <Chip
                  clickable
                  className={classes.colorBtn}
                  style={{ backgroundColor: `${item}` }}
                  key={index}
                  onClick={() => handleClickColor(item)}
                  data-testid={`color-chip-${index}`}
                  onDelete={() => {}}
                  deleteIcon={
                    item == selectedColor ? (
                      <DoneIcon
                        style={{ color: "white" }}
                        data-testid={"done-icon"}
                      />
                    ) : (
                      <DoneIcon
                        style={{ display: "none" }}
                        data-testid={"done-icon"}
                      />
                    )
                  }
                />
              );
            })}
          </Grid>
          <Grid
            container
            className={classes.colorBtnContainer}
            style={{ justifyContent: "start" }}
          >
            <Chip
              clickable
              className={classes.colorBtn}
              style={{ backgroundColor: `#b3bac5` }}
              onClick={() => handleClickColor("")}
              onDelete={() => {}}
              deleteIcon={
                "" == selectedColor ? (
                  <DoneIcon
                    style={{ color: "white" }}
                    data-testid={"done-icon"}
                  />
                ) : (
                  <DoneIcon
                    style={{ display: "none" }}
                    data-testid={"done-icon"}
                  />
                )
              }
            />
            <Typography>No color.</Typography>
          </Grid>
        </Grid>
      )}
      {editMode == 0 && (
        <Grid
          container
          className={classes.actions}
          justify="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            data-testid="test-btnCreateLabel"
            onClick={createLabel}
          >
            Create a new label
          </Button>
        </Grid>
      )}
      {editMode != 0 && (
        <Grid container className={classes.actions} justify="space-between">
          <Button
            color={"primary"}
            variant="contained"
            onClick={saveLabel}
            data-testid={"test-btnSaveLabel"}
          >
            Save
          </Button>
          <Button
            color={"secondary"}
            variant="contained"
            onClick={deleteLabel}
            data-testid={"test-btnDeleteLabel"}
          >
            Delete
          </Button>
        </Grid>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return state;
};
const actions = {
  doCreateLabel,
  doUpdateLabel,
  doDeleteLabel,
  doUpdateActivity,
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(LabelContainer));
