/**
 * This source code is proprietary. Read more in the
 * LICENSE file in the root directory of this source tree.
 */

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "@atlaskit/css-reset";

import * as serviceWorker from "./serviceWorker";

import { configureStore } from "./app/store/configureStore";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Header from "./components/Header";
import App from "./scenes/App";
import Footer from "./components/Footer";

const store = configureStore();

ReactDOM.render(
  <div>
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="md">
          <Header />
          <Provider store={store}>
            <App />
          </Provider>
          <Footer />
        </Container>
      </ThemeProvider>
    </Router>
  </div>,
  document.getElementById("root")
);

serviceWorker.unregister();
