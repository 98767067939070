/**
 * Header - contents above the App (not part of the App) such as instruction text.
 */
import React from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import logo from "../../logo.png";

export default withStyles((theme) => ({
  root: {
    paddingTop: 130,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 130,
    },
  },
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: 300,
    backgroundColor: theme.palette.primary.main,
    zIndex: -1,
  },
  logo: {
    position: "absolute",
    top: 44,
    left: "50%",
    transform: "translateX(-50%)",
    width: 200,
    height: 200,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "20px 24px 64px 0 rgba(0, 0, 0, 0.05)",
    "& img": {
      height: 150,
      width: 150,
    },
    [theme.breakpoints.down("xs")]: {
      top: 40,
      width: 200,
      height: 200,
      "& img": {
        height: 150,
        width: 150,
      },
    },
  },
  heading: {
    borderRadius: 27,
    paddingTop: 134,
    paddingBottom: 53,
    maxWidth: 640,
    margin: "0 auto",
  },
  text: {
    maxWidth: 562,
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 280,
    },
  },
}))(({ classes }) => (
  <div data-testid="header" className={classes.root}>
    <div className={classes.background} />
    <Paper elevation={0} className={classes.logo}>
      <img data-testid="header-logo" alt="Logo" src={logo} />
    </Paper>
    <Paper elevation={0} className={classes.heading}>
      <Typography
        data-testid="header-title"
        variant="h2"
        className={classes.text}
      >
        Collaborate on your meeting agenda here
      </Typography>
    </Paper>
  </div>
));
