import cuid from "cuid";

import { SET_MEETING, SET_LABELS } from "./types";
import firebase from "../app/config/config";

const db = firebase.firestore();

/** Returns a new unique meeting id and stored in redux store */
export const doCreateMeeting = () => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  try {
    const firestore = getFirestore();

    let id = cuid();
    const date = new Date();
    const data = {
      meeting: {
        title: "Let's have a great meeting",
        id: id,
        location: "",
        startDate: {seconds:date.getTime()},
        startTime: {seconds:date.getTime()},
        endTime: null,
        labels:[]
      },
      agendaActivities: {
        id: cuid(),
        isNewActivity: true,
        content: "",
        type: "Information",
        duration: 30,
        description: "",
        index: 0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        lables:[]
      },
    };

    await firestore.set(
      {
        collection: "meetings",
        doc: id,
      },
      data.meeting
    );
    await firestore.set(
      {
        collection: "meetings",
        doc: id,
        subcollections: [
          { collection: "agendaActivities", doc: data.agendaActivities.id },
        ],
      },
      data.agendaActivities
    );
    let meeting = getState().meetingData;
    meeting = { ...meeting, ...data };
    dispatch({ type: SET_MEETING, payload: meeting });
  } catch (error) {
    console.log(error);
  }
};

export const doUpdateMeeting = (meeting) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const { id, title, location, startDate, startTime, endTime } = meeting;
  try {
    await db.collection("meetings").doc(id).set(
      {
        title,
        location,
        startDate,
        startTime,
        endTime,
      },
      { merge: true }
    );

  } catch (error) {
    console.log(error);
  }
};

export const doCopyMeeting = (id) => async(
  dispatch,
  getState,
  {getFirestore}

)=>{
  try{
    let data = {};
    const firestore = getFirestore();
    let meetings = await db.collection("meetings").doc(id).get();
    if (!meetings.exists) {
          alert("No record found!");
    } else {
          const meeting = meetings.data();
          meeting.modified = "";
          await db.collection("meetings").doc(id).set(meeting);
          let newId = cuid();
          console.log(newId);
          meeting.id = newId;
          meeting.created = Date.now();
          await firestore.set(
            {
              collection: "meetings",
              doc: newId,
            },
            meeting
          );
          data.meeting = meeting;
          const docs = await db.collection("meetings").doc(id).collection("agendaActivities").get();
          const batch = db.batch();
          let activities = [];
          docs.forEach((doc) => {
            batch.set(db.collection("meetings").doc(newId).collection("agendaActivities").doc(doc.id), doc.data());
            const docData = doc.data();
            activities.push({
              id: id,
              index: docData.index,
              content: docData.content,
              type: docData.type,
              duration: docData.duration,
              created: firebase.firestore.FieldValue.serverTimestamp(),
              updated: firebase.firestore.FieldValue.serverTimestamp(),
              isNewActivity: docData.isNewActivity,
              description: docData.description,
              labels: docData.labels
            })
          });
          batch.commit();
          data.agendaActivities = activities;
        };
    dispatch({ type: SET_MEETING,  payload:data });

  }catch(error){
    console.log(error);
  }
}
export const doDeleteMeeting = (id) => async(
  dispatch,
  getState,
  {getFirestore}
)=>{
  try{
    console.log(id);
    let activities = await db.collection("meetings")
            .doc(id).collection("agendaActivities").get();
    activities.forEach(async(activity)=>{
      await db.collection("meetings").doc(id).collection("agendaActivities").doc(activity.id).delete();
    })
    //await db.collection("meetings").doc(id).delete();
  }catch(error){
    console.log(error);
  }
}

export const getMeetingById = (id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  try {
    var meeting = {};


    await db
      .collection("meetings")
      .doc(id)
      .get()
      .then((meetings) => {
        if (!meetings.exists) {
          alert("No record found!");
        } else {
          meeting = meetings.data();
        }
      });

    var data = {
      meeting,
    };

    dispatch({ type: SET_MEETING, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const doCreateActivity = () => (dispatch, getState) => {
  const {
    firestore: {
      ordered: { agendaActivities },
    },
    meetingData,
  } = getState();
  const activities = Array.from(agendaActivities);
  const meetingId = meetingData.meeting.id;

  const id = cuid();
  const newActivity = {
    id,
    index: activities.length,
    content: "",
    type: "Information",
    duration: 30,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    updated: firebase.firestore.FieldValue.serverTimestamp(),
    isNewActivity: true,
    labels:[]
  };
  activities.push(newActivity);

  dispatch({
    type: "SET_STATE",
    payload: { meetingData: { ...meetingData, activities } },
  });

  return db
    .collection("meetings")
    .doc(meetingId)
    .collection("agendaActivities")
    .doc(id)
    .set(newActivity);
};

export const doSortActivities = ({ newIndex, oldIndex }) => async (
  dispatch,
  getState
) => {
  const {
    firestore: {
      ordered: { agendaActivities },
    },
    meetingData,
  } = getState();

  const activities = agendaActivities.map((a) => {
    if (newIndex < oldIndex && a.index >= newIndex && a.index < oldIndex) {
      return { ...a, index: a.index + 1 };
    }
    if (newIndex > oldIndex && a.index > oldIndex && a.index <= newIndex) {
      return { ...a, index: a.index - 1 };
    }
    if (a.index === oldIndex) {
      return { ...a, index: newIndex };
    }
    return a;
  });

  dispatch({
    type: "SET_STATE",
    payload: { meetingData: { ...meetingData, activities } },
  });

  const batch = db.batch();
  activities.forEach((activity) => {
    const { id, index, content, type, description, labels, created, duration, isNewActivity } = activity;
    const doc = db
      .collection("meetings")
      .doc(meetingData.meeting.id)
      .collection("agendaActivities")
      .doc(id);

    batch.set(doc, {
      index: index,
      content: content || "",
      type: type || "",
      duration: duration || 0,
      labels: labels || [],
      created: created || firebase.firestore.FieldValue.serverTimestamp(),
      updated: firebase.firestore.FieldValue.serverTimestamp(),
      isNewActivity: isNewActivity,
      description: description || "",
    });
  });
  return batch.commit();
};

export const doDeleteActivity = (activityId) => (dispatch, getState) => {
  const {
    meetingData: {
      meeting: { id: meetingId },
    },
  } = getState();
  const {
    firestore: {
      ordered: { agendaActivities },
    },
  } = getState();

  const activities = agendaActivities
    .filter((a) => a.id !== activityId)
    .sort((a1, a2) => a1.index - a2.index)
    .map((a, index) => ({ ...a, index }));

  const batch = db.batch();
  const activitiesCollection = db
    .collection("meetings")
    .doc(meetingId)
    .collection("agendaActivities");

  // delete removed activity and reorder existing ones
  batch.delete(activitiesCollection.doc(activityId));
  activities.forEach((activity) => {
    batch.set(activitiesCollection.doc(activity.id), {
      ...activity,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });
  });

  return batch.commit();
};

export const doUpdateActivity = (activity) => (dispatch, getState) => {
  const {
    firestore: {
      ordered: { agendaActivities },
    },
    meetingData,
  } = getState();
  const { id, index, content, type, duration, created, isNewActivity, labels, description } = activity;
  const meetingId = meetingData.meeting.id;
  console.log(activity);
  const activities = agendaActivities.map((a) => {
    if (a.id === id) {
      return { ...a, index, content, type, duration, isNewActivity, description };
    }
    return a;
  });
  activities.push(activity);
  dispatch({
    type: "SET_STATE",
    payload: { meetingData: { ...meetingData, activities } },
  });

  return db
    .collection("meetings")
    .doc(meetingId)
    .collection("agendaActivities")
    .doc(id)
    .set({
      index: index,
      content: content || "",
      type: type || "",
      duration: duration || 0,
      description: description || "",
      created: created || firebase.firestore.FieldValue.serverTimestamp(),
      updated: firebase.firestore.FieldValue.serverTimestamp(),
      isNewActivity: isNewActivity,
      labels: labels || []
    });
};

export const doCreateLabel = (label, meetingId) => async (dispatch)=>{
  var meeting = null;
    await db
      .collection("meetings")
      .doc(meetingId)
      .get()
      .then((meetings) => {
        if (!meetings.exists) {
          alert("No record found!");
        } else {
          meeting = meetings.data();

        }
      });
    if(meeting){
      if(!Array.isArray(meeting.labels)){
        meeting.labels = [];
      }
      meeting.labels.push(label);
      await db
      .collection("meetings")
      .doc(meetingId)
      .set(meeting);
    }
}

export const doUpdateLabel = (label, meetingId, index)=>async(dispatch)=>{
  var meeting = null;
    await db
      .collection("meetings")
      .doc(meetingId)
      .get()
      .then((meetings) => {
        if (!meetings.exists) {
          alert("No record found!");
        } else {
          meeting = meetings.data();

        }
      });
    if(meeting){
      if(!Array.isArray(meeting.labels)){
        meeting.labels = [];
      }
      meeting.labels[index] = label;
      await db
      .collection("meetings")
      .doc(meetingId)
      .set(meeting);
    }
}

export const doDeleteLabel = (meetingId, lableIndex) => async(dispatch)=>{
  var meeting = null;
  await db
    .collection("meetings")
    .doc(meetingId)
    .get()
    .then((meetings) => {
      if (!meetings.exists) {
        alert("No record found!");
      } else {
        meeting = meetings.data();

      }
    });
  if(meeting){
    if(!Array.isArray(meeting.labels)){
      meeting.labels = [];
    }
    meeting.labels = meeting.labels.filter((value, index)=>{
      if(lableIndex==index) return false;
      return true;
    })
    await db
    .collection("meetings")
    .doc(meetingId)
    .set(meeting);
  }
}
