import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";

import initialMeetingData from "../../services/reducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  meetingData: initialMeetingData,
});

export default rootReducer;
