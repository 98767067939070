import { SET_MEETING, SET_STATE } from "./types";

import { createReducer } from "../app/common/util/reducerUtil";

const initialState = {
  meeting: "",
};

export const setMeeting = (state, payLoad) => {
  return { ...state, meeting: payLoad.meeting };
};

export const setReducerState = (state, payLoad) => {
  return { ...state, ...payLoad };
};

export default createReducer(initialState, {
  [SET_MEETING]: setMeeting,
  [SET_STATE]: setReducerState,
});
