const formatNumber = (d) => (d > 9 ? d : `0${d}`);

export const formatDuration = (t) => {
  const hours = Math.floor(t / 60);
  const minutes = t % 60;
  return `${formatNumber(hours)}:${formatNumber(minutes)}`;
};

export const parseDuration = (s) => {
  const parts = s.replace(/_/g, "0").split(":");
  const hours = Number.parseInt(parts[0]);
  const minutes = Number.parseInt(parts[1]);
  return hours * 60 + minutes;
};
