import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopy";
import Agenda from "../Agenda";
import AgendaSharing from "../Agenda/agendaSharing";
import LocationForm from "../Agenda/locationInput";
import AgendaDate from "../Agenda/agendaDate";

import { doUpdateMeeting } from "../../services/actions";
import keyCodes from "../../app/common/util/keyCodes";

//create, copy, delete action
import { ButtonGroup, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  doCreateMeeting,
  doDeleteMeeting,
  doCopyMeeting,
} from "../../services/actions";

import Popover from '@material-ui/core/Popover';
import LabelContainer from "../Labels"

const actions = {
  doUpdateMeeting,

  //create, copy, delete action
  doCreateMeeting,
  doDeleteMeeting,
  doCopyMeeting,
};

const styles = (theme) => ({
  root: {
    borderRadius: 22,
    padding: 20,
    maxWidth: 640,
    margin: "0 auto",
  },
  location: {
    font: "inherit",
    width: "100%",
    border: "1px",
    borderRadius: "4px",
    height: "1.1876em",
    margin: 0,
    display: "block",
    padding: "6px 0 7px",
    background: "none",
    boxSizing: "content-box",
    marginTop: "16px",
    marginBottom: "8px",
  },
  title: {
    padding: "20px 0 30px 0",
    minHeight: 110,
    flexWrap: "nowrap",
  },
  titleInput: {
    margin: 0,
    fontSize: 26,
    fontWeight: "bold",
    lineHeight: 1.5,
  },
  titleText: {
    textAlign: "left",
    padding: "6px 0 7px",
    cursor: "pointer",
  },
  button: {
    margin: theme.spacing(1),
  },
  actions: {
    "& a": {
      "&:hover, &:active, &:visited": {
        color: "initial",
      },
    },
  },
});

const Meeting = (props) => {
  const { meetingData, activities, classes, history } = props;
  //const { doCreateMeeting, doDeleteMeeting } = props;
  const title = meetingData && meetingData.title;
  const location = meetingData && meetingData.location;
  const id = meetingData && meetingData.id;
  //const start_time = meetingData && meetingData.start_time;
  const start_time = 300;
  const [isEdit, setIsEdit] = useState(false);
  const [titleInputValue, setTitleInputValue] = useState(title);
  const [showInput, setShowInput] = useState(false);
  //create copy, delete actions
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const createMeeting = () => {
    const { doCreateMeeting } = props;
    doCreateMeeting();
  };
  const deleteMeeting = () => {
    const { doDeleteMeeting } = props;
    console.log(id);
    doDeleteMeeting(id);
    setOpen(false);
    doCreateMeeting();
  };
  const copyMeeting = () => {
    console.log(id);
    const { doCopyMeeting } = props;
    doCopyMeeting(id);
  };
  ///
  const applyTitleChange = () => {
    const { doUpdateMeeting } = props;
    const { id, location, startDate, startTime, endTime } = meetingData;
    const title = titleInputValue;
    doUpdateMeeting({ id, title, location, startDate, startTime, endTime });
  };

  const durationCount = activities
    .filter((a) => {
      return !a.isNewActivity;
    })
    .reduce((sum, a) => sum + a.duration, 0);

  const exitEditMode = () => {
    applyTitleChange();
    setIsEdit(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === keyCodes.ESC || e.keyCode === keyCodes.ENTER) {
      exitEditMode();
    }
  };

  const setIsEditTrue = () => {
    setIsEdit(true);
    setTitleInputValue(title);
  };


  // push meeting id to browser url when component mounts
  useEffect(() => id && history.push(`/m/${id}`), [id, history]);

  return (
    <Paper
      data-testid="meeting"
      data-meetingid={id}
      elevation={0}
      className={classes.root}
    >
      {id && (
        <Grid item xs={12} sm={12} md={8}>
          {!showInput ? (
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Location"
              data-testid="location-data"
              fullWidth
              value={location}
              onClick={() => setShowInput(!showInput)}
            />
          ) : (
            <LocationForm setShowInput={setShowInput} meeting1={meetingData} />
          )}
        </Grid>
      )}
      {id && <AgendaDate meeting={meetingData} />}
      <Grid container alignItems="center" className={classes.title}>
        {isEdit && (
          <ClickAwayListener onClickAway={exitEditMode}>
            <Input
              className={classes.titleInput}
              multiline
              autoFocus
              fullWidth
              value={titleInputValue}
              onKeyDown={onKeyDown}
              onChange={(e) => setTitleInputValue(e.target.value)}
              onBlur={exitEditMode}
              inputProps={{
                ["data-testid"]: "meeting-title-input",
              }}
            />
          </ClickAwayListener>
        )}
        {!isEdit && (
          <Typography
            data-testid="meeting-title"
            className={classes.titleText}
            onClick={() => setIsEditTrue()}
            variant="h3"
          >
            {title}
          </Typography>
        )}
      </Grid>
      {id && activities && (
        <Agenda
          meetingId={id}
          start_time={start_time}
          activities={activities}
          meeting={meetingData}
        />
      )}
      {id && (
        <AgendaSharing
          durationCount={durationCount}
          meeting={meetingData}
          classes={classes}
        />
      )}
      {/* create, copy, delete actions */}
      <Grid container justify="flex-end">
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
        >
          <Button
            variant="contained"
            color="primary"
            id={"createMeetingBtn"}
            className={classes.button}
            startIcon={<CreateIcon />}
            onClick={createMeeting}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<CopyIcon />}
            id={"copyMeetingBtn"}
            onClick={copyMeeting}
          >
            Duplicate
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}
            id={"deleteMeetingBtn"}
            onClick={handleClickOpen}
          >
            Delete
          </Button>
        </ButtonGroup>


      </Grid>

      <Dialog
        open={open}
        data-testid={"delete-dialog"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete meeting? It will be permanently
            removed!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            data-testid={"btnCancel"}
            onClick={handleClose}
            color="primary"
          >
            No, Cancel
          </Button>
          <Button
            variant="contained"
            data-testid={"btnYes"}
            onClick={deleteMeeting}
            color="secondary"
            autoFocus
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default compose(
  withRouter,
  connect(null, actions),
  withStyles(styles)
)(Meeting);
